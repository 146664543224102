<template>
    <div>
      <el-row :gutter="20" class="dialog-body" style="margin-left:0;margin-right:0;margin-top:50px">
        <h1 v-if="item.value === 1" class="boldFont font20 padding_b_16 dialog_ticketName_sm" style="color:#333;">1 Day Studio Pass</h1>
        <h1 v-if="item.value === 2" class="boldFont font20 padding_b_16 dialog_ticketName_sm" style="color:#333;">2 Day Studio Pass</h1>
        <h1 v-if="item.value === 5" class="boldFont font20 padding_b_16 dialog_ticketName_sm" style="color:#333;">1.5 Day Studio Pass</h1>
        <h1 v-if="item.value === 7" class="boldFont font20 padding_b_16 dialog_ticketName_sm" style="color:#333;">NO LIMIT! Countdown 2024 PARTY PASS</h1>
        <!-- <h1 class="boldFont font20 padding_b_16 dialog_ticketName_sm" style="color:#333;">1 Day Studio Pass + Have Fun in Kansai</h1> -->
        <div v-if="item.value === 9" class="dialog_title mb10">1 Day Studio Pass</div>
        <div class="flex_col_reverse">
          <!-- 一日票详情 -->
          <el-col v-if="item.value === 1 || item.value === 9" :span="24" :sm="12" class="didalog-body-left" style="padding-left:0;padding-right:16px;">
            <div style="color:#333333;">
              <ul>
                <li style="font-weight: bold;color:red">This ticket is non-refundable and cannot be changed in the park.</li>
                <li>Once you exit the park, re-entry to the park is not allowed.</li>
                <li>Please come to the entrance gate with a QR code ticket printed.</li>
                <li>When more than one QR code ticket is purchased, each guest has a respective QR code and hold it over the entrance gate terminal.</li>
                <li>Please do not alter or fold the QR code.</li>
                <li>When guests enter the Park, their identity may be confirmed regarding their purchase of the QR code ticket. Please bring your identification.</li>
                <li>Not applicable for separately ticketed special events or operating hours unless stated otherwise on the pass.</li>
                <li>Reselling any QR code ticket or providing any QR code ticket for the purpose of reselling to a third party is strictly prohibited whether it is for profit or not.</li>
                <li>QR code tickets that have been obtained by breaching any of these prohibited points become void. Refunds of those tickets are not allowed.</li>
              </ul>
            </div>
            <div style="color:#333333;margin-top: 16px;">
              <div class="dialog_title mb10" v-if="item.value === 9">Have Fun in Kansai</div>
              <ul>
                <li><div v-if="item.value === 9">A must-have E-ticket package for sightseeing in Kansai. Select popular facilities and restaurants in Kansai area. You can choose 3 of your favorite facilities within a week.</div></li>
                <li><div v-if="item.value === 9">Please click below to check Terms & Conditions.</div></li>
                <li><a v-if="item.value === 9" target="_blank" href="https://www.tripellet.com/hfkansai/en">https://www.tripellet.com/hfkansai/en</a></li>
              </ul>
            </div>
          </el-col>
          <!-- 1.5日票详情 -->
          <el-col v-if="item.value === 5" :span="24" :sm="12" class="didalog-body-left" style="padding-left:0;padding-right:16px;">
            <div style="color:#333333;">
              <ul>
                <li>Valid from 3:00 pm on the first day.</li>
                <li>Valid for 2 consecutive days.</li>
                <li>This ticket is non-refundable and cannot be changed in the park.</li>
                <li>Once you exit the park, re-entry to the park is not allowed.</li>
                <li>Please come to the entrance gate with a QR code ticket printed.</li>
                <li>When more than one QR code ticket is purchased, each guest has a respective QR code and hold it over the entrance gate terminal.</li>
                <li>Please do not alter or fold the QR code.</li>
                <li>When guests enter the Park, their identity may be confirmed regarding their purchase of the QR code ticket. Please bring your identification.</li>
                <li>Not applicable for separately ticketed special events or operating hours unless stated otherwise on the pass.</li>
                <li>Reselling any QR code ticket or providing any QR code ticket for the purpose of reselling to a third party is strictly prohibited whether it is for profit or not.</li>
                <li>QR code tickets that have been obtained by breaching any of these prohibited points become void. Refunds of those tickets are not allowed.</li>
              </ul>
            </div>
          </el-col>
          <!-- 二日票详情 -->
          <el-col v-if="item.value === 2" :span="24" :sm="12" class="didalog-body-left" style="padding-left:0;padding-right:16px;">
            <!-- <h2 class="boldFont font20">2 Day Studio Pass</h2> -->
            <div style="color:#333333;">
              <ul>
                <li>Valid for 2 consecutive days.</li>
                <li>This ticket is non-refundable and cannot be changed in the park.</li>
                <li>Once you exit the park, re-entry to the park is not allowed.</li>
                <li>Please come to the entrance gate with a QR code ticket printed.</li>
                <li>When more than one QR code ticket is purchased, each guest has a respective QR code and hold it over the entrance gate terminal.</li>
                <li>Please do not alter or fold the QR code.</li>
                <li>When guests enter the Park, their identity may be confirmed regarding their purchase of the QR code ticket. Please bring your identification.</li>
                <li>Not applicable for separately ticketed special events or operating hours unless stated otherwise on the pass.</li>
                <li>Reselling any QR code ticket or providing any QR code ticket for the purpose of reselling to a third party is strictly prohibited whether it is for profit or not.</li>
                <li>QR code tickets that have been obtained by breaching any of these prohibited points become void. Refunds of those tickets are not allowed.</li>
              </ul>
            </div>
          </el-col>
             <!-- 年票詳情 -->
          <el-col v-if="item.value === 7" :span="24" :sm="12" class="didalog-body-left" style="padding-left:0;padding-right:16px;">
            <!-- <h2 class="boldFont font20">2 Day Studio Pass</h2> -->
            <div style="color:#333333;">
              <ul>
                <div style="font-weight: 700;">A special ticket is required to attend "NO LIMIT! Countdown 2024."</div>
                <div>You can enjoy the Park as much as you like from 19:00 on December 31 to 21:00 on January 1.</div>
                <li>Studio Passes for December 31 (from Park opening to 17:00) and January 1 (02:00–21:00) will be sold as usual.</li>
                <li>Guests with Party Passes may enter and exit the Park freely from 19:00 on December 31, 2023, until the Park closes at 21:00 on January 1, 2024.</li>
                <li>Super Nintendo World will be closed for maintenance from 2:00 to 6:30 in the morning.</li>
                <li>Operation of some attractions will be suspended during certain times.</li>
                <li>The event program may be changed without notice due to weather or other conditions.</li>
                <div>For details about NO LIMIT! Countdown 2024, please visit our official website.</div>
                <a target="_blank" href="https://www.usj.co.jp/web/en/us/events/countdown-2024/no-limit-countdown-2024">https://www.usj.co.jp/web/en/us/events/countdown-2024/no-limit-countdown-2024</a>
              </ul>
            </div>
          </el-col>
          <el-col :span="24" :sm="12" class="dialog-body-right" style="padding-right:16px;padding-left:16px;">
            <!-- 票计数器 -->
            <div class="counter-box">        
                <!-- 年票 -->
              <div v-if="item.allYearVotes >= 0 && item.value===7" class="flex-space-between">
                <div class="mr15" style="padding-top:4px;">
                  <p class="font16">Ticket from:</p>
                  <p class="font12">All Ages</p>
                </div>
                <div class="mr15 ticketPrice_box">
                  <h3 v-if="getTicketPrice(item.value)" class="per_ticket_price">{{ getTicketPrice(item.value).allYearPrice }}</h3>
                  <p class="font12">per ticket</p>
                </div>
                <div class="input-number">
                  <span @click="item.allYearVotes > 0? votesLess(item,'allage') :'' " class="blueFont font24 less">-</span>
                  <span class="blueFont boldFont">{{ item.allYearVotes }}</span>
                  <span v-if="item.allYearVotes<4" @click="votesAdd(item,'allage')" class="blueFont plus">+</span>
                  <span v-else class="blueFont plus" style="background:#eee;cursor:no-drop;">+</span>
                </div>
              </div>
              <!-- 成人票 -->
              <div v-if="item.adultVotes >= 0 && item.value!==7" class="flex-space-between">
                <div class="mr15" style="padding-top:4px;">
                  <p class="font16">Adult ticket from:</p>
                  <p class="font12">Adults(12+)</p>
                </div>
                <div class="mr15 ticketPrice_box">
                  <h3 v-if="getTicketPrice(item.value)" class="per_ticket_price">{{ getTicketPrice(item.value).adultPrice }}</h3>
                  <p class="font12">per ticket</p>
                </div>
                <div class="input-number">
                  <span @click="item.adultVotes === 0? '' : votesLess(item,'adult')" class="blueFont font24 less">-</span>
                  <span class="blueFont boldFont">{{ item.adultVotes }}</span>
                  <span v-if="item.adultVotes<4" @click="item.adultVotes++" class="blueFont plus">+</span>
                  <span v-else class="blueFont plus" style="background:#eee;cursor:no-drop;">+</span>
                </div>
              </div>
              <!-- 儿童票 -->
              <div v-if="item.childVotes >= 0 && item.value!==7 && item.value!==9" class="flex-space-between" style="margin-top:24px;">
                <div class="mr15" style="padding-top:4px;">
                  <p class="font16">Child ticket from:</p>
                  <p class="font12">Children(4~11)</p>
                </div>
                <div class="mr15 ticketPrice_box">
                  <h3 v-if="getTicketPrice(item.value)" class="font20 per_ticket_price">{{ getTicketPrice(item.value).childPrice }}</h3>
                  <p class="font12">per ticket</p>
                </div>
                <div class="input-number">
                  <span @click="item.childVotes === 0? '' : votesLess(item,'child')" class="blueFont less">-</span>
                  <span class="blueFont boldFont">{{ item.childVotes }}</span>
                  <span v-if="item.childVotes<4" @click="item.childVotes++" class="blueFont plus">+</span>
                  <span v-else class="blueFont plus" style="background:#eee;cursor:no-drop;">+</span>
                </div>
              </div>
              <!-- 老人票 -->
              <div v-if="item.seniorVotes >= 0 &&item.name==='一日票'" class="flex-space-between" style="margin-top:24px;">
                <div class="mr15" style="padding-top:4px;">
                  <p class="font16">Senior ticket from:</p>
                  <p class="font12">Senior(65+)</p>
                </div>
                <div class="mr15 ticketPrice_box">
                  <h3 v-if="getTicketPrice(item.value)" class="font20 per_ticket_price">{{ getTicketPrice(item.value).seniorPrice }}</h3>
                  <p class="font12">per ticket</p>
                </div>
                <div class="input-number">
                  <span @click="item.seniorVotes === 0? '' : votesLess(item,'senior')" class="blueFont less">-</span>
                  <span class="blueFont boldFont">{{ item.seniorVotes }}</span>
                  <span v-if="item.seniorVotes<4" @click="item.seniorVotes++" class="blueFont plus">+</span>
                  <span v-else class="blueFont plus" style="background:#eee;cursor:no-drop;">+</span>
                </div>
              </div>
              <p class="counter_tip">
                Prices per ticket vary by day, with tax.
                <br>
                Up to 4 tickets can be purchased at a time.
                <br>
                <span v-show="item.value===7">Same price for adults, children (4 and up), and seniors</span>
              </p>
            </div>
          </el-col>
        </div>
      </el-row>
      <el-row class="dialog-foot">
        <el-col>
          <div class="foot-btns">
            <el-button class="blueFont cancel_btn" @click="cancel()" style="border-color:#036ce2;">CANCEL</el-button>
            <el-button class="selectDate_btn" @click="selectDate()" :disabled="item.childVotes == 0 && item.adultVotes == 0 && item.seniorVotes == 0 && item.allYearVotes == 0" :type="item.adultVotes!==0 || item.childVotes!==0 || item.seniorVotes!==0 || item.allYearVotes!==0? 'primary':'info'">SELECT A DATE</el-button>
          </div>
        </el-col>
      </el-row>
      <!-- 弹框 -->
     
      <close-icon  @closeDialog="closeDialog" />
    </div>
  </template>
  <script>
 
  import closeIcon from '@/components/close_icon.vue'
  export default ({
    components: {  closeIcon },
    data() {
      return {
     
        item: {}
      }
    },
    created(){
        this.item = JSON.parse(decodeURI(this.$route.query.item));
    },
    methods: {
      votesLess(item,type){
        type === 'allage' ? item.allYearVotes--:''
        type === 'adult' ? item.adultVotes--:''
        type === 'child' ? item.childVotes--:''
        type === 'senior' ? item.seniorVotes--:''
        this.$emit('VotesLess',item)
      },
       votesAdd(item,type){
        type === 'allage' ? item.allYearVotes++:''
      },
      cancel() {
        this.$router.push({ path: "/" });
         this.$emit('footShow')
      },
      // 获取当前环境的票价格
      getTicketPrice(type) {
        let option = process.env.VUE_APP_TICKETPRICE
        option = eval("(" + option + ")")
        return option[type]
      },
      // 打开日期弹框
      selectDate() {
      
        this.$router.push({ path: "/homeDateDialog", query: { item: encodeURI(JSON.stringify(this.item)) } });
        
  
  
      },
      handleTicket() {
        this.$emit('handleTicket')
      },
      // 关闭弹框
      closeDialog() {
        this.$router.push({ path: "/" });
        this.$emit('closeDialog')
      }
    },
  })

  
  </script>
  
  <style lang="scss" scoped>
    .dialog_title {
    background-color: #003399;
    color: white;
    font-weight: bold;
    padding: 15px 10px;
}
  .dialog-body {
    padding: 0 32px;
    // height: 47.8vh;
    overflow: auto;
    margin-bottom: 32px;
  }
  .counter-box {
    display: flex;
    flex-direction: column;
    padding-top: 35px;
    min-width: 309px;
  }
  .input-number {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 24px;
    min-width: 87px;
    .plus {
      display: inline-block;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      border: 2px solid gainsboro;
      line-height: 15px;
      text-align: center;
      margin-left: 10px;
      cursor: pointer;
    }
    .less {
      display: inline-block;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      border: 2px solid gainsboro;
      line-height: 15px;
      text-align: center;
      margin-right: 10px;
      cursor: pointer;
    }
  }
  .ticketPrice_box {
    flex: 1;
    text-align: right;
  }
  .foot-btns {
    .el-button {
      border-radius: 12px;
      background: transparent;
      font-weight: 500;
    }
    .el-button + .el-button {
      margin-left: 16px;
    }
    .el-button--primary {
      background-color: #036ce2;
    }
    .el-button--info.is-disabled {
      background-color: #c8c9cc;
    }
  }
  ::v-deep .el-dialog__body {
    padding: 0;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    word-break: normal;
  }
  ::v-deep .el-dialog__header {
    display: none;
  }
  ::v-deep .didalog-body-left {
    border-right: 1px solid gainsboro;
  }
  ::v-deep .dialog-foot {
    border-top: 1px solid #a7a7a7;
    text-align: right;
    background-color: #eeeeee;
    padding: 32px 32px 16px;
    border-radius: 0 0 12px 12px;
  }
  ::v-deep .el-dialog {
    padding: 30px 0 0 0;
    max-width: 900px;
    border-radius: 12px;
    // margin-top: 94px !important;
  }
  .counter_tip {
    margin-top: 24px;
    font-size: 12px;
  }
  .per_ticket_price {
    font-weight: 500;
    font-size: 24px;
    color: #0a0a0a;
  }
  ul {
    padding-left: 35px;
  }
  @media (max-width: 768px) {
    .flex_col_reverse {
      display: flex;
      flex-direction: column-reverse;
    }
    .didalog-body-left {
      border-right: 0;
      border-right: none;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    .counter-box {
      padding-top: 15px;
    }
    .dialog-body {
      padding-left: 16px;
      padding-right: 16px;
      margin-bottom: 15px;
    }
    .dialog-body-right {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    ul {
      padding-left: 15px;
    }
    /deep/ .el-dialog {
      margin-top: 0 !important;
      margin-bottom: 0;
      height: 100vh;
      border-radius: 0;
      .el-dialog__body {
        height: 100%;
        .dialog-body {
          height: inherit;
        }
      }
    }
    .priceDetail p {
      white-space: nowrap;
    }
    /deep/ .foot-btns {
      display: flex;
      flex-direction: column-reverse;
      padding-top: 8px;
      .el-button + .el-button {
        margin-left: 0;
      }
      .cancel_btn {
        border: none;
        background: transparent;
        font-weight: 500;
        color: #036ce2;
        padding: 16px 0;
      }
      .selectDate_btn {
        padding: 16px 24px;
      }
    }
    .dialog-foot {
      padding: 16px;
      border-radius: 0;
      // padding-bottom: 58px;
    }
    .dialog_ticketName_sm {
      font-size: 24px;
      font-weight: 900;
      color: #333;
    }
  }
  
  .padding_b_16 {
    padding-bottom: 16px;
  }
  </style>